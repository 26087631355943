<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<div class="tab-pane animated fadeInUpShort show active go">
			<div class="row my-3">
				<div class="col-md-12">
					<div class="card r-0 shadow">
						<div class="card-header">
							
							
							<div class="row my-3">
								<div class="col-md-6">
									<!-- 搜索 start -->
									<div class="input-group w-100 m-3 form-inline"><div class="input-group-btn">
										<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">
										
										<span v-if="user_role== 'ADMIN'">
											<select v-model="selected_dept_id" class="form-control  ml-2">
												<option value="0">所有企业</option>
												<option v-bind:key="index" 
													:value="item.id" v-for="(item,index) in departmentlist">
													{{item.department_name}}</option>
											</select>
										</span>
										
										<button @click="search" type="button" class="btn btn-secondary ml-2"><i class="icon-search"></i>
										</button>
										</div>
									</div>
									<!-- 搜索 end -->
								</div>
								<div class="col-md-6 ">
									<div class="float-right m-3">
										<button  @click="add" type="button" class="btn btn-secondary">创建消息</button>
									</div>	
								</div>	
							</div>	
						</div>
	
						<div class="table-responsive">
							<div>
								<table class="table table-striped table-hover r-0 mb-0">
									<thead>
										<tr class="no-b">
											<th style="width: 60px">
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" id="checkedAll" class="custom-control-input"><label class="custom-control-label" for="checkedAll"></label>
												</div> -->
												 <div class="d-none d-lg-block">序号</div>
											</th>
											<th>
												<div class="d-none d-lg-block">消息类型</div>
											</th>
											<th v-if="dept_id==0">
												<div class="d-none d-lg-block">企业</div>
											</th>
											<th>
												<div class="d-none d-lg-block">消息标题</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">消息内容</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">发布时间</div>
											</th>
											
											<th></th>
										</tr>
									</thead>
	
									<tbody>
										<tr v-for="(item,index) in messagelist" v-bind:key="index" v-show="item.is_default != 1" 
										:class="{'blue lighten-5':selectedId == item.id}">
											<td>
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input checkSingle" id="user_id_32" required=""><label class="custom-control-label" for="user_id_1"></label>
												</div> -->
												<div class="d-none d-lg-block">{{index+1}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.type==1?'企业通知':'系统消息'}}</div>
											</td>
											
											<td v-if="dept_id==0">
												<div class="text-ellipsis" style="width: 180px;">
												<!-- {{item.dept_id}} -->
												<template v-if="deptmap.hasOwnProperty(item.dept_id)">
													{{deptmap[item.dept_id].department_name}}
												</template>
												{{item.dept_id==0?'所有企业':''}}
												</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block font-weight-bold">{{item.title}}</div>
											</td>
											
											<td>
												<div style="width: 480px;" class="text-ellipsis">
												<p>{{item.content}}</p>
												</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.create_date}}</div>
											</td>
											
											<td>
												<button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs"> 编辑 </button>
												<!-- <button
													@click.stop="removeReady(index)" type="button"
													class="btn btn-outline-danger btn-xs ml-1"> 删除 </button> -->
																							 
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<div class="row">
				<div class="col-sm-12 col-md-5">
					<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
						显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
					</div>
				</div>
				<div class="col-sm-12 col-md-7">
					
					<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
					
				</div>
			</div>
	
		</div>
		
		<!--Add New Message Fab Button-->
		<div @click="add" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></div>
		
		<!-- modal start -->
		<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">{{selectedIndex ==-1?'添加消息':'修改消息'}}</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
					<div class="form-group" v-if="dept_id==0">
						<label for="" class="s-12 font-weight-bold">归属企业</label>
						<select v-model="message.dept_id" class="form-control">
							<option value="0">所有企业</option>
							<template v-for="(item,index) in departmentlist">
							<option v-bind:key="index"
								:value="item.id"  v-if="item.enabled==1">
								{{item.department_name}}</option>
							</template>	
						</select>
						<div class="s-12">
						</div>	
					</div>
					
		          <div class="form-group">
		            <label for="" class="s-12 font-weight-bold">消息标题</label>
		            <input v-model="message.title" type="text" class="form-control"  placeholder="消息标题">
		            <div class="valid-feedback">
						
		            </div>
		          </div>
		          
				  <div class="form-group">
				    <label for="" class="s-12 font-weight-bold">消息内容</label>
				    <!-- <input v-model="message.content" type="text" class="form-control" placeholder="消息内容"> -->
					<!-- <textarea  v-model="message.content" cols="30" rows="10" class="form-control" ></textarea> -->
				    <template>
				      <div>
						<quill-editor v-model="message.content" :options="editorOption"></quill-editor>
				      </div>
				    </template>
					<small class="form-text text-muted">
						仅支持纯文本，可进行缩进、换行、设置文本颜色。
				    </small>
				  </div>
				  
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
				 <button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- modal end -->
		
	</div>		
</template>

<script>
	import VPagination from '../components/my-page.vue'
	
export default {
    data: function() {
        return {
			title: '消息',
			user_role: '',
			
			token: '',
			
			// setting: {},
			// outletlist: [],
			
			ent_id: 0,
			dept_id: 0,
			
			ent_name: '',
			dept_name: '',
			// user_role: '',
			user_name: '',
			
			messagelist: [],
			query: '',
			
			message: {},
			selectedIndex: -1,
			selectedId: 0,
			
			disabled: false,
			
			current_page: 1,
			page_size: 50,
			count: 0,
			total_page: 0,
			show_from: 0,
			show_to: 0,
			
			total: 0, // 记录总条数
			display: 10, // 每页显示条数
			current: 1, // 当前的页数
			
			editorOption:{
				
			},
			
			departmentlist: [],
			// dept_id: '',
			deptmap: {}, //key为 dept id, value为 dept
			
			selected_dept_id: 0,
        }
    },
    props: {
      
    },
	
	components: {
		VPagination
	},
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得店铺信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		self.dept_id = lsLoginData.dept_id;
		self.user_role = lsLoginData.user_role;
		console.info('--- dept_id ->',self.dept_id)
		
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		if(self.user_role == 'ADMIN'){
			self.init(() => {
				self.initData();
			})
		}
		else{
			self.initData();
		}
	},
    methods:{
		init(callback) {
			let self = this;
		
			//
			this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
					params: {
						token: self.token,
						// dept_id: self.dept_id,
						// query: self.query,
						// current_page: self.current_page,
						// page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						self.departmentlist = data.data.departments;
						
						for (let i = 0; i < self.departmentlist.length; i++) {
							let dept = self.departmentlist[i];
							self.deptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.deptmap);
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
		
		},
		
    	initData() {
    		let self = this;
			
			// 组装数据
			let postData = {
				token: self.token,
				// dept_id: self.selected_dept_id,
				type: self.dept_id==0?0:1,
				query: self.query,
				current_page: self.current_page,
				page_size: self.page_size,
			}
			
			if(self.dept_id > 0){//B企业用户需要传入dept_id
				postData['dept_id'] = self.dept_id;
			}
			else{
				if(self.selected_dept_id>0){
					postData['dept_id'] = self.selected_dept_id;
				}
			}
			console.log('--- postData->',postData)
    	
    		//
    		this.axios.get(this.GLOBAL.baseURI + 'getMessageList', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				if (data.code == 200) {
    					self.messagelist = data.data.message_list;
    					// __LIST = data.data.messages;
						
						self.current_page = data.data.page_num;
						self.page_size = data.data.page_size;
						self.count = data.data.total;
						self.total_page = Math.ceil(self.count / self.page_size);
						console.log('--- self.total_page ->', self.total_page)
						
						self.show_from = self.page_size * (self.current_page - 1) + 1;
						self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
							.current_page : self.count;
						
						self.total = self.count; // 记录总条数
						self.display = self.page_size; // 每页显示条数
						self.current = self.current_page; // 当前的页数
    				} 
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    	
    		let self = this;
			
    		// self.messagelist = __LIST.filter(item => item.message_name.indexOf(self.query) != -1);
			self.initData();
    	},
    	
    	add() {
    		var self = this;
    	
    		// location.href = 'editcategory.html';	
    	
    		self.selectedIndex = -1;
    		self.message = {
				id: 0,
				dept_id: self.dept_id,
				type: self.dept_id==0?0:1,
				title: '',
				content: '',
			};
			
    		$('#modal').modal('show');
    	},
    	
    	edit(index) {
    		console.log(index);
    		let self = this;
    	
    		// let cg = self.messagelist[index];
    	
    		self.selectedIndex = index;
    		self.message = Object.assign({}, self.messagelist[index]);
			self.selectedId = self.message.id;
    		console.log(self.selectedIndex, self.message);
    	
    		self.oldName = self.message.message_name;
    	
    		$('#modal').modal('show');
    	},
    	
    	save() {
    		let self = this;
    		console.log(self.message);
    		// return;
			
			if(self.disabled) return;
    	
    		//校验
    		if (!self.message.title) {
				this.$toasted.error('请输入消息标题', {
					position: 'top-center',
				}).goAway(2000)
    			return;
    		}
			
			if (!self.message.content) {
				this.$toasted.error('请输入消息内容', {
					position: 'top-center',
				}).goAway(2000)
				return;
			}
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			dept_id: self.message.dept_id,
    			message_id: self.selectedIndex == -1 ? '':self.message.id,//添加时为0
    			title: self.message.title,
				content: self.message.content,
				type: self.message.type,
    		}
			
			console.log('--- postData ->',postData)
    	
    		this.axios.get(this.GLOBAL.baseURI + 'saveMessage', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						if(data.data.result_code == 'success'){
							if (self.selectedIndex == -1) {
								self.messagelist.unshift(data.data.message);
							} else {
								// self.messagelist.splice(self.selectedIndex, 1, data.data);
								console.log('--- 更新部门返回 data.data ->',data.data.message)
								self.$set(self.messagelist, self.selectedIndex, data.data.message)
							}
							    	
							    	
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    					
    				} 
					else if (data.code == 101) {
    					//登录超时
						$('#modal').modal('hide');
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
		
		
		removeReady(index){
			console.log(index);
			let self = this;
			
			self.selectedIndex = index;
			self.message = Object.assign({}, self.messagelist[index]);
			
			self.$confirm({
				title: '警告',
				message: '删除消息将无法恢复，确定删除？',
				button: {
					no: '取消',
					yes: '确定',
				},
				callback:(res)=>{
					console.log(res)
					if(res){
						self.remove();
					}
					
				}
			});
		},
    	
    	
    	remove() {
    		let self = this;
    		console.log(self.message);
    		// return;
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			message_id: self.message.id,
    		}
    	
    		this.axios.get(this.GLOBAL.baseURI + 'delMessage', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
    	
    					self.messagelist.splice(self.selectedIndex, 1);
    	
						self.$toasted.success('删除成功', {
							position: 'top-center',
						}).goAway(2000)
    	
    					$('#modal').modal('hide');
    				} 
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
    	
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
		
		pagechange: function(currentPage) {
			console.log(currentPage);
			// ajax请求, 向后台发送 currentPage, 来获取对应的数据
			this.current_page = currentPage;
			this.initData();
		},
    }
}
</script>

<style>
	.text-ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.ql-editor {
		height: 200px;
	}

</style>
